import request from "@/utils/axios";
import type { ListResponse } from "@/types";
import type { ActType, ActsParams } from "../types";

interface Status {
  id: number;
  count: number;
  key: string;
  value: string;
}

export async function getAllActs(
  params: ActsParams = {},
): Promise<ListResponse<ActType[]> & { statuses: Status[] }> {
  const result: ListResponse<ActType[]> & { statuses: Status[] } =
    await request({
      url: "/violations/",
      method: "get",
      params,
    });

  return result;
}

export async function getAct(id: string): Promise<ActType> {
  const result: ActType = await request({
    url: `/violations/${id}/`,
    method: "get",
  });

  return result;
}
