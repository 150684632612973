import request from "@/utils/axios";
import type { BaseParams, ListResponse, Region } from "@/types";
import type {
  Article,
  Reason,
  StatusCount,
  Violation,
  ViolationDoc,
} from "../types";

export async function getRegions(
  params: BaseParams = {},
): Promise<ListResponse<Region[]>> {
  const result: ListResponse<Region[]> = await request({
    url: "/config/regions/",
    method: "get",
    params,
  });

  return result;
}

export async function getInspectors(params: any): any {
  const result: any = await request({
    url: "/account/users/",
    method: "get",
    params,
  });

  return result;
}

export async function getArticles(
  params: BaseParams = {},
): Promise<ListResponse<Article[]>> {
  const result: ListResponse<Article[]> = await request({
    url: "/articles/",
    method: "get",
    params,
  });

  return result;
}

export async function getDocTypes(
  params: BaseParams = {},
): Promise<ListResponse<ViolationDoc[]>> {
  const result: ListResponse<ViolationDoc[]> = await request({
    url: "/violation-document-types/",
    method: "get",
    params,
  });

  return result;
}

export async function getViolationTypes(
  params: BaseParams = {},
): Promise<ListResponse<Violation[]>> {
  const result: ListResponse<Violation[]> = await request({
    url: "/violation-types/",
    method: "get",
    params,
  });

  return result;
}

export async function getReasons(params: BaseParams = {}): Promise<Reason[]> {
  const result: Reason[] = await request({
    url: "/violations-reasons-list/",
    method: "get",
    params,
  });

  return result;
}

// export async function updateViolationType(
//   id: string,
//   data: { violation_type: number },
// ): Promise<void> {
//   await request({
//     url: `/violations/${id}/update-violation-type/`,
//     method: "put",
//     data,
//   });
// }

export async function changeViolationToAdministrative(
  id: string,
  // data: { violation_type: number },
): Promise<void> {
  await request({
    url: `/violations/${id}/administrative/`,
    method: "put",
    // data,
  });
}

export async function changeViolationToCriminal(
  id: string,
  // data: { violation_type: number },
): Promise<void> {
  await request({
    url: `/violations/${id}/criminal/`,
    method: "put",
    // data,
  });
}

// export async function updateViolationStatus(
//   id: string,
//   data: { status: string; reason: number; description?: string },
// ): Promise<void> {
//   await request({
//     url: `/violations/${id}/update-status/`,
//     method: "put",
//     data,
//   });
// }

export async function updateViolationStatus(
  id: string,
  data: { status: string; reason: number; description?: string },
): Promise<void> {
  await request({
    url: `/violations/${id}/return/`,
    method: "put",
    data,
  });
}

export async function getStatusCount(
  params: BaseParams = {},
): Promise<StatusCount[]> {
  const result: StatusCount[] = await request({
    url: "/status-list/",
    method: "get",
    params,
  });

  return result;
}

export async function getStatusCountList(
  params: BaseParams = {},
): Promise<StatusCount[]> {
  const result: StatusCount[] = await request({
    url: "/violations/status-count-list/",
    method: "get",
    params,
  });

  return result;
}
