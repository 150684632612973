/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from "react";
import getOrFetch from "@/helpers/get-or-fetch";
import getUserClass from "@/helpers/get-user-class";
import canSee from "@/helpers/can-see";
import {
  actQuery,
  actsQuery,
  articlesQuery,
  reasonsQuery,
  violationTypesQuery,
} from "./queries";

const Container = lazy(() => import("./views/container"));
const Acts = lazy(() => import("./views/acts"));
const Act = lazy(() => import("./views/act"));
const NoteIcon = lazy(() => import("./components/note-icon"));

const actsListRoutes = canSee(
  {
    id: "acts-list",
    title: "acts-list",
    path: "list-of-acts",
    element: <Container of={<Acts />} />,
    Icon: NoteIcon,
    // loader: async () => {
    //   const resources = await Promise.all([
    //     getOrFetch(regionsQuery),
    //     getOrFetch(articlesQuery),
    //     getOrFetch(violationDocsQuery),
    //     getOrFetch(violationTypesQuery),
    //   ]);

    //   return resources;
    // },
    children: [
      {
        id: "act",
        title: "Act",
        path: ":actId",
        element: <Act />,
        loader: async ({ params }) => {
          const [act, articles, reasons, violationTypes] = await Promise.all([
            getOrFetch(() => actQuery(params.actId as string)),
            getOrFetch(articlesQuery),
            getOrFetch(reasonsQuery),
            getOrFetch(violationTypesQuery),
          ]);

          if (act === null) {
            // replace to any unspecified route to trigger catch all route
            window.location.replace("/not-found");
            return null;
          }

          const acts = getOrFetch(() => actsQuery(act));

          return [act, acts, articles, reasons, violationTypes];
        },
      },
    ],
  },
  getUserClass().role,
);

export default actsListRoutes;
