import CustomModal from "@/components/modal";
import {
  Button,
  ConfigProvider,
  Flex,
  Image,
  Input,
  Layout,
  Select,
  Spin,
} from "antd";
import formatPhoneNumber from "@/helpers/format-phone-number";
import formatDate from "@/helpers/format-date";
import phoneIcon from "@/assets/phone.svg";
import arrowLeftIcon from "@/assets/arrow-left.svg";
import { Info } from "@/features/acts-list";
import TabularData from "@/components/tabular-data";
import "swiper/css";
import "swiper/css/navigation";
import useDetailsState from "./state";
import Link from "../../components/link";
import CancelIcon from "../../components/cancel-icon";
import TickIcon from "../../components/tick-icon";
import { EyeOutlined } from "@ant-design/icons";

const { Header } = Layout;

export default function Details(): React.ReactElement {
  const {
    Title,
    t,
    contextHolder,
    colorBgContainer,
    goBack,
    reason,
    setReason,
    note,
    setNote,
    reasons,
    onNullify,
    onReceive,
    onReturn,
    formDetails,
    isFormDetailsLoading,
    setIsReturnModalOpen,
    setIsCancelModalOpen,
    isReturnModalOpen,
    onReturnModalCancel,
    isCancelModalOpen,
    onCancelModalCancel,
  } = useDetailsState();

  const nullified = false;
  console.log("formDetails", formDetails);

  return (
    <>
      {contextHolder}

      <Header
        style={{ background: colorBgContainer }}
        className="px-8 pt-2 flex items-center gap-4"
      >
        <button type="button" onClick={goBack}>
          <img src={arrowLeftIcon} alt="back" width={24} height={24} />
        </button>
        <h1 className="font-semibold text-2xl">{t("form-details")}</h1>
      </Header>

      <div className="bg-white p-8">
        <Spin spinning={isFormDetailsLoading}>
          <div className="flex items-center justify-between bg-[#fafbfc] border rounded-lg p-4 my-4">
            <div className="flex flex-col justify-center">
              <span className="text-[#081C15] font-medium text-base">
                {formDetails?.employee?.lastname}{" "}
                {formDetails?.employee?.firstname}{" "}
                {formDetails?.employee?.middlename}
              </span>
              <span className="text-[#40916C]">{t("inspector")}</span>
            </div>

            <span className="flex items-center gap-2">
              <img src={phoneIcon} alt="phone" />
              {typeof formDetails?.number !== "undefined"
                ? formatPhoneNumber(
                    formDetails?.created_by?.phone,
                    "+### ## ### ## ##",
                  )
                : null}
            </span>
          </div>

          <TabularData
            rows={[
              {
                name: t("serial-number"),
                value: `${formDetails?.region?.serial ?? ""} ${
                  formDetails?.region?.code ?? ""
                }`.trim(),
              },
              {
                name: t("datetime"),
                value: formatDate(formDetails?.act_date ?? ""),
              },
              {
                name: t("address"),
                value: `${formDetails?.region?.name ?? ""}, ${
                  formDetails?.district?.name ?? ""
                }`.trim(),
              },
            ]}
          />

          <TabularData
            rows={[
              {
                name: t("Istemolchi FIO"),
                value:
                  `${formDetails?.employee?.firstname} ${formDetails?.employee?.lastname} ${formDetails?.employee?.middlename}`.trim(),
              },
              {
                name: t("Lavozim"),
                value: formDetails?.employee?.position,
              },
            ]}
          />

          <TabularData
            rows={[
              {
                name: t("Korxona nomi"),
                value: formDetails?.violation_organization?.name,
              },
              {
                name: t("Abonent raqami"),
                value: formDetails?.violation_organization?.contract_number,
              },
              {
                name: t("Joylashgan manzili"),
                value: formDetails?.violation_organization?.address,
              },
              {
                name: t("STIR"),
                value: formDetails?.violation_organization?.stir,
              },
              {
                name: t("Rahbari"),
                value: formDetails?.violation_organization?.director_fio,
              },
            ]}
          />

          <TabularData
            rows={[
              {
                name: t("Gaz hisoblagich rusumi"),
                value: formDetails?.counter?.counter_type,
              },
              {
                name: t("Zavod №"),
                value: formDetails?.counter?.factory_number,
              },
              {
                name: t("Ko'rsatkichi"),
                value: formDetails?.counter?.indicator,
              },
            ]}
          />

          <TabularData
            rows={[
              {
                name: t("Qoidabuzarlik bandlari"),
                value: [
                  formDetails?.law_article?.name
                    ? `${formDetails?.law_article?.name} - ${t("band")}`
                    : "",
                  formDetails?.law_article?.prim
                    ? `, ${formDetails?.law_article?.prim} - ${t(
                        "kichik band",
                      )}`
                    : "",
                  formDetails?.law_article?.number
                    ? `, ${formDetails?.law_article?.number} - ${t("qism")}`
                    : "",
                ]
                  .filter((s) => s !== "")
                  .join(" "),
              },
            ]}
          />

          <TabularData
            rows={[
              {
                name: t("Loyiha bo'yicha gaz uskunalari"),
                value: t("Loyihadan tashqari gaz uskunalari"),
              },
              { name: "", value: "" },
            ]}
          />

          <TabularData
            rows={[{ name: t("Fabula"), value: formDetails?.fabula }]}
          />

          <TabularData
            rows={[
              {
                name: t("Qoidabuzarlik sodir etgan shaxs"),
                value:
                  `${formDetails?.violation_person?.firstname} ${formDetails?.violation_person?.lastname} ${formDetails?.violation_person?.middlename}`.trim(),
              },
              {
                name: t("Imzo"),
                value: (
                  <Link
                    href={formDetails?.violation_person?.signature ?? ""}
                    baseless
                  />
                ),
              },
            ]}
          />

          <TabularData
            rows={[
              {
                name: t("participant-organ"),
                value: `${formDetails?.participant_organization?.name}`,
              },
              {
                name: t("participant-name"),
                value: (
                  <Link href={formDetails?.participant_full_name} baseless />
                ),
              },
              {
                name: t("Imzo"),
                value: (
                  // <Link href={formDetails?.participant_signature} baseless />
                  <Flex className="p-[12px]">
                    <Image
                      src={formDetails?.participant_signature}
                      width={70}
                      height={70}
                      preview={{
                        mask: <EyeOutlined />,
                      }}
                    />
                  </Flex>
                ),
              },
            ]}
          />

          <TabularData
            rows={[
              {
                name: t("Dalolatnoma tuzuvchi"),
                value:
                  `${formDetails?.employee?.firstname} ${formDetails?.employee?.lastname} ${formDetails?.employee?.middlename}`.trim(),
              },
              {
                name: t("Imzo"),
                value: (
                  // <Link href={formDetails?.employee?.signature} baseless />
                  <Flex className="p-[12px]">
                    <Image
                      src={formDetails?.employee?.signature}
                      width={70}
                      height={70}
                      // change text of preview icon in mask of image
                      preview={{
                        mask: <EyeOutlined />,
                      }}
                    />
                  </Flex>
                ),
              },
            ]}
          />

          {formDetails?.witness.length > 0 ? (
            formDetails?.witness?.map(
              ({ firstname, lastname, middlename, signature }) => (
                <TabularData
                  key={`witness-${firstname}-${lastname}-${middlename}`}
                  rows={[
                    {
                      name: t("Guvoh"),
                      value: `${firstname} ${lastname} ${middlename}`.trim(),
                    },
                    {
                      name: t("Imzo"),
                      value: (
                        // <Link href={signature} baseless />
                        <Flex className="p-[12px]">
                          <Image
                            src={signature}
                            width={70}
                            height={70}
                            preview={{
                              mask: <EyeOutlined />,
                            }}
                          />
                        </Flex>
                      ),
                    },
                  ]}
                />
              ),
            )
          ) : (
            <TabularData
              rows={[
                { name: t("Guvoh"), value: "" },
                {
                  name: t("Imzo"),
                  value: "",
                },
              ]}
            />
          )}

          {formDetails?.status?.key === "under_consideration" && (
            <div className="flex justify-between">
              <ConfigProvider
                theme={{
                  token: { colorPrimary: "#EB5757" },
                }}
              >
                <Button
                  className="text-[#EB5757] border-[#EB5757]"
                  size="large"
                  onClick={() => {
                    setIsCancelModalOpen(true);
                  }}
                >
                  {t("nullify")}
                </Button>
              </ConfigProvider>

              <div className="flex gap-4">
                <Button
                  type="text"
                  className="bg-[#f3f5f7]"
                  size="large"
                  onClick={() => {
                    setIsReturnModalOpen(true);
                  }}
                >
                  {t("return-inspector")}
                </Button>

                <Button
                  type="primary"
                  className="bg-[#40916C]"
                  size="large"
                  onClick={onReceive}
                >
                  {t("receive")}
                </Button>
              </div>
            </div>
          )}
        </Spin>
      </div>

      <CustomModal
        title={
          <div className="flex flex-col items-center gap-2">
            {nullified ? (
              <div className="bg-[#fafbfc] w-[96px] h-[96px] rounded-3xl flex items-center justify-center">
                <TickIcon style={{ color: "#40916C" }} />
              </div>
            ) : (
              <div className="bg-[#FAFBFC] w-[96px] h-[96px] rounded-3xl flex items-center justify-center">
                <CancelIcon style={{ color: "#EB5757" }} />
              </div>
            )}

            <Title level={4} className="text-center">
              {nullified ? t("success") : t("cancel-form")}
            </Title>
          </div>
        }
        open={isCancelModalOpen}
        footer={null}
        onCancel={onCancelModalCancel}
        centered
        width={400}
      >
        {nullified ? (
          <p className="text-center text-sm text-[#62738C]">
            {t("rejected-success")}
          </p>
        ) : (
          <p className="text-center text-sm text-[#62738C]">
            {t("sure-nullify")}
          </p>
        )}
        <br />
        <div className="flex justify-center gap-4">
          {nullified ? (
            <Button
              type="primary"
              size="large"
              className="bg-[#D8F3DC] text-[#40916C] w-[140px]"
              onClick={() => {
                setIsCancelModalOpen(false);
              }}
            >
              {t("close")}
            </Button>
          ) : (
            <>
              <Button
                type="text"
                size="large"
                className="bg-[#f3f5f7] w-[140px]"
                onClick={() => {
                  setIsCancelModalOpen(false);
                }}
              >
                {t("cancel")}
              </Button>

              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#EB5757",
                  },
                }}
              >
                <Button
                  type="primary"
                  size="large"
                  className="bg-[#EB5757] w-[140px]"
                  onClick={onNullify}
                >
                  {t("nullify")}
                </Button>
              </ConfigProvider>
            </>
          )}
        </div>
      </CustomModal>

      <CustomModal
        title={
          <Title level={4} className="text-center">
            {t("return-form")}
          </Title>
        }
        open={isReturnModalOpen}
        footer={null}
        onCancel={onReturnModalCancel}
        centered
        width={800}
      >
        <Info
          of={t("choose-return-reason")}
          className="flex flex-col gap-2"
          rootClassName="my-6"
        >
          <ConfigProvider
            theme={{
              components: {
                Select: {
                  colorBgContainer: "#fafbfc",
                },
              },
            }}
          >
            <Select
              placeholder={t("reason")}
              options={reasons}
              value={reason}
              onChange={(val) => {
                setReason(val);
              }}
              className="bg-[#fafbfc]"
            />
          </ConfigProvider>

          <Input.TextArea
            rows={4}
            placeholder={t("note") ?? ""}
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
            className="bg-[#fafbfc]"
          />
        </Info>

        <div className="flex justify-end gap-4">
          <Button
            type="text"
            size="large"
            className="bg-[#f3f5f7]"
            onClick={() => {
              setIsReturnModalOpen(false);
            }}
          >
            {t("cancel")}
          </Button>

          <Button
            type="text"
            size="large"
            className="bg-[#f3f5f7]"
            onClick={onReturn}
          >
            {t("return")}
          </Button>
        </div>
      </CustomModal>
    </>
  );
}
