/* eslint-disable @typescript-eslint/no-unused-vars */

interface URLSearchParams {
  parse: () => Record<string, string>;
}

URLSearchParams.prototype.parse = function parse() {
  const parsedParams: Record<string, string> = {};

  this.forEach((value, key) => {
    if (parsedParams[key]) {
      parsedParams[key] =
        typeof parsedParams[key] === "string"
          ? [parsedParams[key], value]
          : [...parsedParams[key], value];
    } else {
      parsedParams[key] = value;
    }
  });

  return parsedParams;
};
