import { useLangContext } from "@/contexts";
import type { DateObj } from "@/helpers/seconds-to-date";
import dayjs from "dayjs";
import type { TFunction } from "i18next";

const displayDeadline = (
  t: TFunction,
  date: DateObj,
  hasPassed = false,
): string => {
  const { lang } = useLangContext();

  if (hasPassed) {
    if (lang === "uzLatin") {
      return dayjs()
        .subtract(date.days ?? 0, "days")
        .subtract(date.hours ?? 0, "hours")
        .subtract(date.minutes ?? 0, "minutes")
        .format(`YYYY-MM-DD HH:mm`);
    }
    return dayjs()
      .subtract(date.days ?? 0, "days")
      .subtract(date.hours ?? 0, "hours")
      .subtract(date.minutes ?? 0, "minutes")
      .format(`DD.MM.YYYY ${t("at")} HH:mm ${t("h")}`);
  }

  if (typeof date.days === "number" && date.days > 0) {
    return `${date.days} ${t("days-till-deadline") ?? ""}`;
  }

  if (typeof date.hours === "number" && date.hours > 0) {
    return `${date.hours} ${t("hours-till-deadline") ?? ""}`;
  }

  return `${date.minutes ?? 0} ${t("minutes-till-deadline") ?? ""}`;
};

export default displayDeadline;
