import request from "@/utils/axios";
import type { AxiosResponse } from "axios";
import type { BaseEntity } from "@/types";
import type { AuthResponse } from "../types";

export async function login(data: {
  username: string;
  password: string;
}): Promise<AuthResponse> {
  const res: AuthResponse = await request({
    url: "/account/me/",
    method: "post",
    data,
  });

  return res;
}

export async function loginOneId(data: {
  code: string;
}): Promise<AuthResponse> {
  const res: AuthResponse = await request({
    url: "/account/login/",
    method: "post",
    params: data,
  });

  return res;
}

export async function register(data: {
  code: string;
  organization: number;
}): Promise<{ username: string }> {
  const res: { username: string } = await request({
    url: "/account/register/",
    method: "post",
    params: data,
  });

  return res;
}

export async function getOrganizations(): Promise<
  BaseEntity[] & { code: number }
> {
  const res: BaseEntity[] & { code: number } = await request({
    url: "/user-organizations/",
    method: "get",
  });

  return res;
}

export async function getPositions(): Promise<
  BaseEntity[] & { code: number }
> {
  const res: BaseEntity[] & { code: number } = await request({
    url: "/account/user-positions/",
    method: "get",
  });

  return res;
}

export async function refreshToken(data: {
  refresh: string;
}): Promise<AxiosResponse<AuthResponse>> {
  const res = await request({
    url: "/account/me/refresh/",
    method: "post",
    data,
  });

  return res;
}

export async function logoutOneId(data: { username: string }): Promise<void> {
  await request({
    url: "/account/logout/",
    method: "post",
    params: data,
  });
}
